const LOGIN_CHECK =  "/api/login_check";
const MODIFIER_PASSWORD =  "/api/user/new_password";
const CONFRIM_CLIENT_ACCOUNT = '/api/clients/confirm/client/account'


//EXPORT URLS
export const AUTH_CONSTANT_API = {
    LOGIN_CHECK,
    MODIFIER_PASSWORD,
    CONFRIM_CLIENT_ACCOUNT
}